import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';
import { EventLayout } from '../../components/entities/event-layout';

export default createGatsbyPageComponent('Event', { layout: EventLayout });

export const QUERY = graphql`
  query EventQuery($id: ID!) {
    platform {
      event(id: $id) {
        id slug path 
        name
        name_fr
        startDate
        endDate
        overview { text }
        sessionInfo { text }
        description { text }
        description_fr { text }
        color { id value darkValue }
        images { image { id url width height } }
        banner {
          id name slug title description { text } alternateText
          image { 
            id url width height 
          }
        }
        links { id url url_fr name label label_fr isDownload page { path } }
        sessions { 
          id name name_fr __typename 
          startDate endDate
          icsPath(base: "/downloads")
          description { text }
          description_fr { text }
          room { id name shortName name_fr shortName_fr }
          color { id value darkValue }
          presenters { 
            id name 
            bio { html }
            bio_fr { html }
            photo { url }
            openGraph { title description { plain } image { thumbnails { full { url } } } }
          }
          group { id name name_fr shortName shortName_fr }
        }
      }
    }
  }
`;
